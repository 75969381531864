export const environment = {
  production: true,
  url: 'https://meteodesservices-rest-dot-fr-water-vedif-meteo-uat.appspot.com/api',

  // https://developers.google.com/identity/sign-in/web/sign-in?refresh=1
  client_id: '481384483593-4772radnrvmeib7647g9glmjtk6312q9.apps.googleusercontent.com',

  // URL de redirection post connexion
  redirectUri: 'https://' + window.location.hostname,

  // config firebase
  firebaseConfig: {
    apiKey: 'AIzaSyAZI4vbGNUfkRKFh-VoKkNoANNZ18xwZ90',
    authDomain: 'fr-water-vedif-meteo-uat.firebaseapp.com',
    databaseURL: 'https://fr-water-vedif-meteo-uat.firebaseio.com',
    projectId: 'fr-water-vedif-meteo-uat',
    storageBucket: 'fr-water-vedif-meteo-uat.appspot.com',
    messagingSenderId: '481384483593',
    appId: '1:481384483593:web:f255ca7e7a118d6d'
  }
};
